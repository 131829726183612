import React from "react";
import ReactDOM from "react-dom";
import queryString from "query-string";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import OrderPaymentFormContainer from "./OrderPaymentFormContainer";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// Call `loadStripe` with the same connected account ID used when creating
// the PaymentIntent.
const stripePromise = loadStripe("pk_live_51HjYAWA5sxx5TgRItdJ7zXh3xOTGrsIA0zxNMR31yuxbBet37nmvvI0y8QMBDKUvK5eunkHaBLbVmLkMhp19AnDD00hyKLMyXd");

const OrderPayment = (props) => {
  let token = queryString.parse(props.location.search).token;
  let orderId = props.match.params.orderId;
  let tripId = props.match.params.tripId;

  return (
    <Elements stripe={stripePromise}>
      <OrderPaymentFormContainer
        token={token}
        orderId={orderId}
        tripId={tripId}
        for={props.for}
      />
    </Elements>
  );
};

export default OrderPayment;
