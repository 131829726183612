import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ReactTable from 'react-table';
import { css } from '@emotion/core';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import BeatLoader from 'react-spinners/BeatLoader';
import SelectSearch from 'react-select-search';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import GridItem from 'components/Grid/GridItem.js';
import Datetime from 'react-datetime';
import TripDetails from 'superAdminViews/ride/trips/TripDetails';
import OrderDetails from 'shopAdminViews/orders/OrderDetails';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import style from './Finance.module.scss';
import Checkbox from '@material-ui/core/Checkbox';

const GET_TRIP_FINANCE = gql`
	query getTripOrdersDetailByAdmin($from: Date!, $to: Date!, $createdAtSort: Int, $driver: ID, $tripType: TripType, $justCompletedPayments: Boolean) {
		getTripOrdersDetailByAdmin(
			filters: { from: $from, to: $to, driver: $driver, tripType: $tripType }
			sort: { createdAt: $createdAtSort }
			conditions: { justCompletedPayments: $justCompletedPayments }
		) {
			commissionForSpark
			driversIncome
			numberOfTrips
			userTakings
			tripOrders {
				_id
				user {
					_id
					fullName
					phoneNumber
				}
				trip {
					_id
					payment
					driver {
						_id
						fullName
						phoneNumber
					}
					passenger
					passengerDetail {
						_id
						fullName
						phoneNumber
					}
					ended
					car {
						_id
					}
					waitTimePrice
					optionsPrice
					optionsPriceDetails {
						option
						price
					}
					tipValue
					tripType
					promotion
					staticMapImageUrl
					tripDistance
					state
					cost
					tripType
					createdAt
					startDate
					endDate
					rate
				}
				payment {
					_id
				}
				commission
				createdAt
				paidAt
				finished
				commented
			}
		}
	}
`;

const GET_SHOP_FINANCE = gql`
	query getOrdersDetailByAdmin($from: Date!, $to: Date!, $createdAtSort: Int, $shop: ID, $justCompletedPayments: Boolean) {
		getOrdersDetailByAdmin(filters: { from: $from, to: $to, shop: $shop }, sort: { createdAt: $createdAtSort }, conditions: { justCompletedPayments: $justCompletedPayments }) {
			benefitForSpark
			shopsIncome
			numberOfOrders
			userTakings
			orders {
				_id
				user {
					_id
					fullName
				}
				shipmentModel
				shop {
					_id
					name
				}
				finalPrice
				commission
				createdAt
				finished
				status
				payment {
					_id
				}
				trip {
					_id
					trackId
					origin {
						coordinates
					}
					destinations {
						coordinates
					}
				}
				tracking {
					trackId
				}
				cart {
					products {
						quantity
						product {
							_id
							title
						}
					}
				}
			}
		}
	}
`;

const GET_DRIVERS = gql`
	query getDriversByAdmin($limit: Int, $skip: Int, $fullName: String) {
		getDriversByAdmin(pagination: { limit: $limit, skip: $skip }, filters: { fullName: $fullName }) {
			_id
			fullName
			phoneNumber
		}
	}
`;

const GET_SHOPS = gql`
	query getShopsByAdmin($limit: Int, $skip: Int, $name: String) {
		getShopsByAdmin(pagination: { limit: $limit, skip: $skip }, filters: { name: $name }) {
			_id
			name
			shopAdmin {
				phoneNumber
				email
			}
		}
	}
`;

const Finance = (props) => {
	const options = ['Shop', 'Ride', 'Delivery', 'Individual Shop', 'Individual Driver'];

	const [part, setPart] = useState(options[1]);
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [driverId, setDriverId] = useState('');
	const [shopId, setShopId] = useState('');

	const [startDateError, setStartDateError] = useState('');
	const [endDateError, setEndDateError] = useState('');
	const [driverIdError, setDriverIdError] = useState('');
	const [shopIdError, setShopIdError] = useState('');

	const [driverSelectOptions, setDriverSelectOptions] = useState([]);
	const [shopSelectOptions, setShopSelectOptions] = useState([]);

	const [modal, setModal] = useState(false);
	const [tripDetail, setTripDetail] = useState();
	const [orderModal, setOrderModal] = useState(false);
	const [orderDetail, setOrderDetail] = useState();

	const [justCompletedPayments, setJustCompletedPayments] = useState(false)

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onSelectOrderDetail = (order) => {
		setOrderModal(true);
		setOrderDetail(order);
	};

	const onSelectTripDetail = (trip) => {
		setModal(true);
		setTripDetail(trip);
	};

	const { loading: driversLoading, error: driversError, data: driversData } = useQuery(GET_DRIVERS, {
		variables: { limit: 10, skip: 0, fullName: '' },
		fetchPolicy: 'network-only',
	});

	const { loading: shopsLoading, error: shopsError, data: shopsData } = useQuery(GET_SHOPS, {
		variables: { limit: 10, skip: 0, name: '' },
		fetchPolicy: 'network-only',
	});

	const [
		getDriversByAdmin,
		{ loading: driversLazyLoading, error: driversLazyError, data: driversLazyData, fetchMore },
	] = useLazyQuery(GET_DRIVERS);

	const [
		getShopsByAdmin,
		{ loading: shopsLazyLoading, error: shopsLazyError, data: shopsLazyData, fetchMore: fetchMoreShops },
	] = useLazyQuery(GET_SHOPS);

	const [fetchMoreSkip, setFetchMoreSkip] = useState(10);
	const [fetchMoreSkipShop, setFetchMoreSkipShop] = useState(10);

	const onLoadMore = () => {
		fetchMore({
			query: GET_DRIVERS,
			variables: { limit: 10, skip: fetchMoreSkip, fullName: search ? search : '' },
			updateQuery: (previousResult, { fetchMoreResult }) => {
				console.log('this is new results:', fetchMoreResult.getDriversByAdmin);
				setDriverSelectOptions((driverSelectOptions) => [
					...driverSelectOptions,
					...Array.from(fetchMoreResult.getDriversByAdmin, (driver) => ({
						name: driver.fullName + ' - ' + driver.phoneNumber,
						value: driver._id,
					})),
				]);
			},
		});
		setFetchMoreSkip((fetchMoreSkip) => fetchMoreSkip + 10);
	};

	const onLoadMoreShops = () => {
		fetchMoreShops({
			query: GET_SHOPS,
			variables: { limit: 10, skip: fetchMoreSkipShop, name: shopSearch ? shopSearch : '' },
			updateQuery: (previousResult, { fetchMoreResult }) => {
				console.log('this is new results:', fetchMoreResult.getShopsByAdmin);
				setDriverSelectOptions((shopSelectOptions) => [
					...shopSelectOptions,
					...Array.from(
						fetchMoreResult.getShopsByAdmin.filter((shop) => shop.shopAdmin && shop.shopAdmin.phoneNumber),
						(shop) => ({
							name: shop.name + ' - ' + shop.shopAdmin.phoneNumber,
							value: shop._id,
						})
					),
				]);
			},
		});
		setFetchMoreSkipShop((fetchMoreSkipShop) => fetchMoreSkipShop + 10);
	};

	const handleScroll = (e) => {
		let element = e.target;
		if (
			Number(element.scrollHeight).toFixed(0) - Number(element.scrollTop).toFixed(0) ==
			Number(element.clientHeight).toFixed(0)
		) {
			onLoadMore();
		}
	};

	const handleScrollShops = (e) => {
		let element = e.target;
		if (
			Number(element.scrollHeight).toFixed(0) - Number(element.scrollTop).toFixed(0) ==
			Number(element.clientHeight).toFixed(0)
		) {
			onLoadMoreShops();
		}
	};

	useEffect(() => {
		if (driversLazyData) {
			console.log(driversLazyData);
			setDriverSelectOptions(
				Array.from(driversLazyData.getDriversByAdmin, (driver) => ({
					name: driver.fullName + ' - ' + driver.phoneNumber,
					value: driver._id,
				}))
			);
		}
	}, [driversLazyLoading, driversLazyData]);

	useEffect(() => {
		if (shopsLazyData) {
			setShopSelectOptions(
				Array.from(
					shopsLazyData.getShopsByAdmin.filter((shop) => shop.shopAdmin && shop.shopAdmin.phoneNumber),
					(shop) => ({
						name: shop.name + ' - ' + shop.shopAdmin.phoneNumber,
						value: shop._id,
					})
				)
			);
		}
	}, [, shopsLazyData, shopsLazyLoading]);

	const [search, setSearch] = useState('');
	const [shopSearch, setShopSearch] = useState('');

	useEffect(() => {
		const handler = setTimeout(() => {
			getDriversByAdmin({
				variables: {
					limit: 10,
					skip: 0,
					fullName: search ? search.trim() : '',
				},
			});
		}, 200);
		return () => clearTimeout(handler);
	}, [search]);

	useEffect(() => {
		const handler = setTimeout(() => {
			getShopsByAdmin({
				variables: {
					limit: 10,
					skip: 0,
					name: shopSearch ? shopSearch.trim() : '',
				},
			});
		}, 200);
		return () => clearTimeout(handler);
	}, [shopSearch]);

	const [getTripOrdersDetailByAdmin, { loading: tripLoading, error: tripError, data: tripData }] = useLazyQuery(
		GET_TRIP_FINANCE
	);

	const [getOrdersDetailByAdmin, { loading: shopLoading, error: shopError, data: shopData }] = useLazyQuery(
		GET_SHOP_FINANCE
	);

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmit();
		}
	};

	const onSubmit = () => {
		if (!startDate) {
			setStartDateError("start date can't be empty*");
		} else {
			if (moment(startDate, 'MM/DD/YYYY', true).isValid() || moment(startDate, 'MM-DD-YYYY', true).isValid()) {
				setStartDateError('');
			} else {
				setStartDateError('invalid date');
			}
		}
		if (!endDate) {
			setEndDateError("end date can't be empty*");
		} else {
			if (moment(endDate, 'MM/DD/YYYY', true).isValid() || moment(endDate, 'MM-DD-YYYY', true).isValid()) {
				setEndDateError('');
			} else {
				setEndDateError('invalid date');
			}
		}
		if (!shopId && part === 'Individual Shop') {
			setShopIdError(true);
		} else {
			setShopIdError(false);
		}
		if (!driverId && part === 'Individual Driver') {
			setDriverIdError(true);
		} else {
			setDriverIdError(false);
		}
		if (
			startDate &&
			endDate &&
			(moment(startDate, 'MM/DD/YYYY', true).isValid() || moment(startDate, 'MM-DD-YYYY', true).isValid()) &&
			(moment(endDate, 'MM/DD/YYYY', true).isValid() || moment(endDate, 'MM-DD-YYYY', true).isValid()) &&
			(part === 'Ride' || part === 'Delivery' || (part === 'Individual Driver' && driverId))
		) {
			getTripOrdersDetailByAdmin({
				variables: {
					from: moment(startDate).format('YYYY-MM-DD'),
					to: moment(endDate).format('YYYY-MM-DD'),
					createdAtSort: -1,
					tripType: part === 'Delivery' || part === 'Ride' ? part.toUpperCase() : undefined,
					driver: part === 'Individual Driver' ? driverId : undefined,
					justCompletedPayments: justCompletedPayments
				},
			});
		}
		if (
			startDate &&
			endDate &&
			(moment(startDate, 'MM/DD/YYYY', true).isValid() || moment(startDate, 'MM-DD-YYYY', true).isValid()) &&
			(moment(endDate, 'MM/DD/YYYY', true).isValid() || moment(endDate, 'MM-DD-YYYY', true).isValid()) &&
			(part === 'Shop' || (part === 'Individual Shop' && shopId))
		) {
			getOrdersDetailByAdmin({
				variables: {
					from: moment(startDate).format('YYYY-MM-DD'),
					to: moment(endDate).format('YYYY-MM-DD'),
					createdAtSort: -1,
					shop: part === 'Individual Shop' ? shopId : undefined,
					justCompletedPayments: justCompletedPayments
				},
			});
		}
	};

	if (tripLoading || shopLoading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (tripError || shopError) {
		console.log(shopError);
		return <ErrorPage />;
	}

	if (tripData) {
		console.log(tripData);
	}

	return (
		<div className={style.mainDiv}>
			{tripData &&
				(part === 'Ride' || part === 'Delivery' || part === 'Individual Driver') &&
				(tripData.getTripOrdersDetailByAdmin.tripOrders.length > 0 ? (
					<div className={style.tableContainer}>
						<ReactTable
							data={tripData.getTripOrdersDetailByAdmin.tripOrders.map((trip) => ({
								passengerPhoneNumber: trip.user && trip.user.phoneNumber ? trip.user.phoneNumber : '',
								passengerName: trip.user && trip.user.fullName ? trip.user.fullName : '',
								createdAt: moment(trip.createdAt).format('MM/DD/YYYY HH:mm'),
								paymentId: trip.trip && trip.trip.payment && (
									<CopyToClipboard text={trip.trip.payment}>
										<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
											{trip.trip.payment}
										</span>
									</CopyToClipboard>
								),
								commission: (
									<div>
										<button
											onClick={() => onSelectTripDetail(trip.trip)}
											className={style.detailIconDiv}
										>
											<span>i</span>
										</button>
										{trip.commission && '$' + trip.commission.toFixed(2)}{' '}
									</div>
								),
								ended: trip.finished ? (
									<div className={`${style.inlineTag} ${style.paidTag}`}>completed</div>
								) : (
									<div className={`${style.inlineTag} ${style.passengercanceledTag}`}>in progress</div>
								),
							}))}
							columns={[
								{
									Header: <div className={style.fitTitleDiv}>Passenger Name</div>,
									accessor: 'passengerName',
									sortable: false,
									filterable: false,
									minWidth: 140,
								},
								{
									Header: <div className={style.fitTitleDiv}>Passenger Phone No</div>,
									accessor: 'passengerPhoneNumber',
									sortable: false,
									filterable: false,
									minWidth: 145,
								},
								{
									Header: <div className={style.fitTitleDiv}>Created at</div>,
									accessor: 'createdAt',
									sortable: false,
									filterable: false,
									minWidth: 140,
								},
								{
									Header: <div className={style.fitTitleDiv}>Commission</div>,
									accessor: 'commission',
									sortable: false,
									filterable: false,
								},
								{
									Header: <div className={style.fitTitleDiv}>Payment</div>,
									accessor: 'paymentId',
									sortable: false,
									filterable: false,
								},
								{
									Header: <div className={style.fitTitleDiv}>Status</div>,
									accessor: 'ended',
									sortable: false,
									filterable: false,
								},
							]}
							defaultPageSize={tripData.getTripOrdersDetailByAdmin.tripOrders.length}
							showPaginationTop={false}
							showPaginationBottom={false}
							className="-striped -highlight"
						/>
					</div>
				) : (
					<div className={style.noRow}>No trips found!</div>
				))}
			{shopData &&
				(part === 'Shop' || part === 'Individual Shop') &&
				(shopData.getOrdersDetailByAdmin.orders.length > 0 ? (
					<div className={style.tableContainer}>
						<ReactTable
							data={shopData.getOrdersDetailByAdmin.orders.map((order) => ({
								trackId: (
									<CopyToClipboard text={order.tracking.trackId}>
										<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
											{order.tracking.trackId}
										</span>
									</CopyToClipboard>
								),
								shopId: (
									<CopyToClipboard text={order.shop._id}>
										<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
											{order.shop._id}
										</span>
									</CopyToClipboard>
								),
								shopName: order.shop.name,
								createdAt: moment(order.createdAt).format('MM/DD/YYYY HH:mm'),
								paymentId: order.payment && (
									<CopyToClipboard text={order.payment._id}>
										<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
											{order.payment._id}
										</span>
									</CopyToClipboard>
								),
								commission: (
									<div>
										<button
											onClick={() => onSelectOrderDetail(order)}
											className={style.detailIconDiv}
										>
											<span>i</span>
										</button>
										{order.commission && '$' + order.commission.toFixed(2)}{' '}
									</div>
								),
								ended: order.finished ? (
									<div className={`${style.inlineTag} ${style.paidTag}`}>completed</div>
								) : (
									<div className={`${style.inlineTag} ${style.passengercanceledTag}`}>in progress</div>
								),
							}))}
							columns={[
								{
									Header: <div className={style.fitTitleDiv}>Track ID</div>,
									accessor: 'trackId',
									sortable: false,
									filterable: false,
								},

								{
									Header: <div className={style.fitTitleDiv}>Shop Name</div>,
									accessor: 'shopName',
									sortable: false,
									filterable: false,
									minWidth: 140,
								},
								{
									Header: <div className={style.fitTitleDiv}>Created at</div>,
									accessor: 'createdAt',
									sortable: false,
									filterable: false,
									minWidth: 140,
								},
								{
									Header: <div className={style.fitTitleDiv}>Commission</div>,
									accessor: 'commission',
									sortable: false,
									filterable: false,
								},
								{
									Header: <div className={style.fitTitleDiv}>Payment</div>,
									accessor: 'paymentId',
									sortable: false,
									filterable: false,
								},
								{
									Header: <div className={style.fitTitleDiv}>Status</div>,
									accessor: 'ended',
									sortable: false,
									filterable: false,
								},
							]}
							defaultPageSize={shopData.getOrdersDetailByAdmin.orders.length}
							showPaginationTop={false}
							showPaginationBottom={false}
							className="-striped -highlight"
						/>
					</div>
				) : (
					<div className={style.noRow}>No order found!</div>
				))}
			<GridContainer>
				<GridItem xs={12} sm={12} md={12} lg={6}>
					<div className={style.form}>
						{props.for === 'total' && (
							<div className={style.dropdownRow}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5}>
										<div className={style.label}>Type:</div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7}>
										<div style={{ textAlign: 'left' }} className={style.dropdownContainer}>
											<CustomDropdown
												hoverColor="primary"
												buttonText={part}
												onClick={(e) => setPart(e)}
												dropdownList={options}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						{part === 'Individual Driver' && (
							<div className={style.dropdownRow}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5}>
										<div className={style.label}>Driver:</div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7}>
										{driverIdError && (
											<div className={style.selectSearchError}>driver can't be empty*</div>
										)}
										<div>
											<SelectSearch
												options={driverSelectOptions}
												value={driverId}
												getOptions={(searchWord) => {
													setSearch(searchWord);
												}}
												onScroll={(e) => handleScroll(e)}
												name=""
												search
												placeholder="Choose driver"
												onChange={(value) => setDriverId(value)}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						{part === 'Individual Shop' && (
							<div className={style.dropdownRow}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5}>
										<div className={style.label}>Shop:</div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7}>
										{shopIdError && (
											<div className={style.selectSearchError}>shop can't be empty*</div>
										)}
										<div>
											<SelectSearch
												options={shopSelectOptions}
												value={shopId}
												getOptions={(searchWord) => {
													setShopSearch(searchWord);
												}}
												onScroll={(e) => handleScrollShops(e)}
												name=""
												search
												placeholder="Choose shop"
												onChange={(value) => setShopId(value)}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						<div onKeyPress={(e) => handleEnter(e)} className={style.dateRow}>
							<GridContainer>
								<GridItem xs={12} sm={5} md={5}>
									<div className={style.dateLabel}>Start date:</div>
								</GridItem>
								<GridItem xs={12} sm={7} md={7}>
									{startDateError && <div className={style.dateError}>{startDateError}</div>}
									<Datetime
										timeFormat={false}
										disableOnClickOutside
										value={startDate}
										closeOnSelect
										onChange={(date) => setStartDate(date)}
										inputProps={{
											placeholder: 'set start date',
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						<div onKeyPress={(e) => handleEnter(e)} className={style.dateRow}>
							<GridContainer>
								<GridItem xs={12} sm={5} md={5}>
									<div className={style.dateLabel}>End date:</div>
								</GridItem>
								<GridItem xs={12} sm={7} md={7}>
									{endDateError && <div className={style.dateError}>{endDateError}</div>}
									<Datetime
										timeFormat={false}
										disableOnClickOutside
										value={endDate}
										closeOnSelect
										onChange={(date) => setEndDate(date)}
										inputProps={{
											placeholder: 'set end date',
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						<div onKeyPress={(e) => handleEnter(e)} className={style.dateRow}>
							<GridContainer>
								<GridItem xs={12} sm={5} md={5}>
									<div className={style.dateLabel}>Just completed payments:</div>
								</GridItem>
								<GridItem xs={12} sm={7} md={7}>
								<Checkbox
										color="default"
										name="Just completed payments"
										checked={justCompletedPayments}
										onChange={() => {
											setJustCompletedPayments(!justCompletedPayments)
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>

						<div style={{ textAlign: 'left' }} className={style.buttonContainer}>
							<Button color="yellow" onClick={onSubmit}>
								Submit
							</Button>
						</div>
					</div>
				</GridItem>
				{tripData && (part === 'Ride' || part === 'Delivery' || part === 'Individual Driver') && (
					<GridItem xs={12} sm={12} md={12} lg={6}>
						<div className={style.data}>
							<div className={style.dataRow}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.dataLabel}>User fare:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<div className={style.dataValue}>
											${tripData.getTripOrdersDetailByAdmin.userTakings.toFixed(2)}
										</div>
									</GridItem>
								</GridContainer>
							</div>
							<div className={style.dataRow}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.dataLabel}>Driver income:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<div className={style.dataValue}>
											${tripData.getTripOrdersDetailByAdmin.driversIncome.toFixed(2)}
										</div>
									</GridItem>
								</GridContainer>
							</div>
							<div className={style.dataRow}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.dataLabel}>Spark commission:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<div className={style.dataValue}>
											${tripData.getTripOrdersDetailByAdmin.commissionForSpark.toFixed(2)}
										</div>
									</GridItem>
								</GridContainer>
							</div>
							<div className={style.dataRow}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.dataLabel}>Number of trips:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<div className={style.dataValue}>
											{tripData.getTripOrdersDetailByAdmin.numberOfTrips}
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</div>
					</GridItem>
				)}
				{shopData && (part === 'Shop' || part === 'Individual Shop') && (
					<GridItem xs={12} sm={12} md={12} lg={6}>
						<div className={style.data}>
							<div className={style.dataRow}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.dataLabel}>User fare:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<div className={style.dataValue}>
											${shopData.getOrdersDetailByAdmin.userTakings.toFixed()}
										</div>
									</GridItem>
								</GridContainer>
							</div>
							<div className={style.dataRow}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.dataLabel}>Shop income:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<div className={style.dataValue}>
											${shopData.getOrdersDetailByAdmin.shopsIncome.toFixed()}
										</div>
									</GridItem>
								</GridContainer>
							</div>
							<div className={style.dataRow}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.dataLabel}>Spark commission:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<div className={style.dataValue}>
											${shopData.getOrdersDetailByAdmin.benefitForSpark.toFixed()}
										</div>
									</GridItem>
								</GridContainer>
							</div>
							<div className={style.dataRow}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.dataLabel}>Number of orders:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<div className={style.dataValue}>
											{shopData.getOrdersDetailByAdmin.numberOfOrders}
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</div>
					</GridItem>
				)}
			</GridContainer>
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<TripDetails close={() => setModal(false)} trip={tripDetail} />
				</DialogContent>
			</Dialog>
			<Dialog open={orderModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<OrderDetails close={() => setOrderModal(false)} order={orderDetail} />
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Finance;
